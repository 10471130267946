(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports, require('worker-timers-broker')) :
    typeof define === 'function' && define.amd ? define(['exports', 'worker-timers-broker'], factory) :
    (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.workerTimers = {}, global.workerTimersBroker));
})(this, (function (exports, workerTimersBroker) { 'use strict';

    var createLoadOrReturnBroker = function createLoadOrReturnBroker(loadBroker, worker) {
      var broker = null;
      return function () {
        if (broker !== null) {
          return broker;
        }
        var blob = new Blob([worker], {
          type: 'application/javascript; charset=utf-8'
        });
        var url = URL.createObjectURL(blob);
        broker = loadBroker(url);
        // Bug #1: Edge up until v18 didn't like the URL to be revoked directly.
        setTimeout(function () {
          return URL.revokeObjectURL(url);
        });
        return broker;
      };
    };

    // This is the minified and stringified code of the worker-timers-worker package.
    var worker = "(()=>{var e={472:(e,t,r)=>{var o,n;void 0===(n=\"function\"==typeof(o=function(){\"use strict\";var e=new Map,t=new Map,r=function(t){var r=e.get(t);return void 0!==r&&(clearTimeout(r),e.delete(t),!0)},o=function(e){var r=t.get(e);return void 0!==r&&(clearTimeout(r),t.delete(e),!0)},n=function(e,t){var r=performance.now(),o=e+t-r-performance.timeOrigin;return{expected:r+o,remainingDelay:o}},i=function e(t,r,o,n){var i=o-performance.now();i>0?t.set(r,setTimeout(e,i,t,r,o,n)):(t.delete(r),postMessage({id:null,method:\"call\",params:{timerId:r,timerType:n}}))},a=function(t,r,o){var a=n(t,o),s=a.expected,u=a.remainingDelay;e.set(r,setTimeout(i,u,e,r,s,\"interval\"))},s=function(e,r,o){var a=n(e,o),s=a.expected,u=a.remainingDelay;t.set(r,setTimeout(i,u,t,r,s,\"timeout\"))};addEventListener(\"message\",(function(e){var t=e.data;try{if(\"clear\"===t.method){var n=t.id,i=t.params,u=i.timerId,d=i.timerType;if(\"interval\"===d)postMessage({id:n,result:r(u)});else{if(\"timeout\"!==d)throw new Error('The given type \"'.concat(d,'\" is not supported'));postMessage({id:n,result:o(u)})}}else{if(\"set\"!==t.method)throw new Error('The given method \"'.concat(t.method,'\" is not supported'));var p=t.params,c=p.delay,m=p.now,l=p.timerId,v=p.timerType;if(\"interval\"===v)a(c,l,m);else{if(\"timeout\"!==v)throw new Error('The given type \"'.concat(v,'\" is not supported'));s(c,l,m)}}}catch(e){postMessage({error:{message:e.message},id:t.id,result:null})}}))})?o.call(t,r,t,e):o)||(e.exports=n)}},t={};function r(o){var n=t[o];if(void 0!==n)return n.exports;var i=t[o]={exports:{}};return e[o](i,i.exports,r),i.exports}r.n=e=>{var t=e&&e.__esModule?()=>e.default:()=>e;return r.d(t,{a:t}),t},r.d=(e,t)=>{for(var o in t)r.o(t,o)&&!r.o(e,o)&&Object.defineProperty(e,o,{enumerable:!0,get:t[o]})},r.o=(e,t)=>Object.prototype.hasOwnProperty.call(e,t),(()=>{\"use strict\";r(472)})()})();"; // tslint:disable-line:max-line-length

    var loadOrReturnBroker = createLoadOrReturnBroker(workerTimersBroker.load, worker);
    var clearInterval = function clearInterval(timerId) {
      return loadOrReturnBroker().clearInterval(timerId);
    };
    var clearTimeout = function clearTimeout(timerId) {
      return loadOrReturnBroker().clearTimeout(timerId);
    };
    var setInterval = function setInterval() {
      var _loadOrReturnBroker;
      return (_loadOrReturnBroker = loadOrReturnBroker()).setInterval.apply(_loadOrReturnBroker, arguments);
    };
    var setTimeout$1 = function setTimeout() {
      var _loadOrReturnBroker2;
      return (_loadOrReturnBroker2 = loadOrReturnBroker()).setTimeout.apply(_loadOrReturnBroker2, arguments);
    };

    exports.clearInterval = clearInterval;
    exports.clearTimeout = clearTimeout;
    exports.setInterval = setInterval;
    exports.setTimeout = setTimeout$1;

}));
